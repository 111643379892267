<template>
  <section>
    <vs-row>
      <vs-col
        class="w-full lg:w-1/3 hidden lg:flex flex-wrap justify-start"
        vs-type="flex"
        vs-align="left"
        vs-w="6"
      >
        <div class="flex items-center">
          <div
            id="profileImageUpload"
            style="cursor: pointer"
            class="img-wrapper ml-5 vs-con-loading__container"
            @click="open_in_new_tab(createUserData.profileImage || defaultPP)"
          >
            <img
              :src="createUserData.profileImage || defaultPP"
              class="img-fluid"
              alt
            />
          </div>
        </div>
        <div class="flex items-center">
          <div class="m-3" style="text-align: center; position: relative">
            <label
              class="image-upload"
              style="
                border: 1px solid #3dc9b3;
                display: inline-block;
                width: 100%;
                padding: 5px;
              "
            >
              Add Profile Photo
              <input
                type="file"
                accept="image/*"
                @change="uploadImage($event, 'profileImageUpload')"
                id="file-input"
                hidden
              />
            </label>
          </div>
        </div>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :success="
              !errors.first('firstName') && createUserData.firstName != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('firstName') ? true : false"
            v-validate="'required|max:150'"
            name="firstName"
            data-vv-as="first name"
            label="First Name"
            icon-no-border
            icon="icon icon-user"
            icon-pack="feather"
            class="w-full"
            :danger-text="errors.first('firstName')"
            v-model="firstNameModel"
          />
        </div>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :success="
              !errors.first('lastName') && createUserData.lastName != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('lastName') ? true : false"
            v-validate="'required|max:150'"
            name="lastName"
            data-vv-as="last name"
            label="Last Name"
            icon-no-border
            icon="icon icon-user"
            icon-pack="feather"
            class="w-full"
            :danger-text="errors.first('lastName')"
            v-model="lastNameModel"
          />
        </div>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :success="!errors.first('email') && createUserData.email != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('email') ? true : false"
            v-validate="'required|email'"
            name="email"
            data-vv-as="email"
            label="Account Email"
            icon-no-border
            icon="icon icon-user"
            icon-pack="feather"
            class="w-full"
            :danger-text="errors.first('email')"
            v-model="emailModel"
          />
        </div>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :success="
              !errors.first('password') && createUserData.password != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('password') ? true : false"
            v-validate="{
              required: true,
              min: 10,
              regex: /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/,
            }"
            name="password"
            data-vv-as="password"
            label="Password"
            icon-no-border
            icon-pack="feather"
            class="w-full"
            :danger-text="errors.first('password')"
            autocomplete="new-password"
            v-model="passwordModel"
            :icon="passwordIcon"
            :type="passwordType"
            @click.native="showHidePassword($event)"
          />
        </div>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-select
            label="Account Type"
            :danger="errors.first('apType') ? true : false"
            :success="!errors.first('apType') && createUserData.apType !== ''"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-as="account type"
            name="apType"
            autocomplete
            v-model="accountTypeModel"
            class="w-full"
            id="apType"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in accountTypeItems"
            />
          </vs-select>
          <span class="text-danger" style="font-size: 0.65rem !important">
            {{ errors.first("apType") }}
          </span>
        </div>
      </vs-col>
    </vs-row>

    <vs-row
      v-if="
        createUserData.apType === 'Nurse Practitioner' ||
        createUserData.apType === 'Physician Assistant'
      " 
    >
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
            <div v-if="!this.$isAuRegion()" class=" mb-12">
               <vs-select
            label="User Type"
            :danger="errors.first('userType') ? true : false"
            :success="!errors.first('userType') && createUserData.userType !== ''"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-as="account type"
            name="userType"
            autocomplete
            v-model="createUserData.userType"
            class="w-full"
            id="userType"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in userTypeItems"
            />
          </vs-select>
        
          
          <span class="text-danger" style="font-size: 0.65rem !important">
            {{ errors.first("userType") }}
          </span>
            </div>
           
       
        
             <vs-select
             v-if="createUserData.userType != 'nurse'"
            :placeholder="this.$isAuRegion() ? 'Select Authorising Dr' : 'Select Supervising Dr' " 
            :label="this.$isAuRegion() ? 'Select Authorising Dr' : 'Select Supervising Dr' " 
            v-model="authorizingDoctorModal"
            class="w-full"
            :danger="errors.first('authorizingDoctor') ? true : false"
            :success="
              !errors.first('authorizingDoctor') &&
              createUserData.authorizingDoctor !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-as="Authorizing Doctor"
            autocomplete
            name="authorizingDoctor"
          >
            <vs-select-item
              :key="index"
              :value="item._id"
              :text="`${item.firstName} ${item.lastName}`"
              v-for="(item, index) in authorisingDoctorsList"
            />
          </vs-select>
          <span class="text-danger" style="font-size: 0.65rem !important">
            {{ errors.first("authorizingDoctor") }}
          </span>
        </div>
      </vs-col>
    </vs-row>
  </section>
</template>

<script>
import { EventBus } from "@/main";
import { mapActions } from "vuex";

export default {
  name: "CreateUserBasic",
  inject: ["$validator"],
  props: ["createUserData"],
  data() {
    return {
      defaultPP: require("@/assets/images/profile/default.png"),
      passwordType: "password",
      passwordIcon: "icon icon-eye-off",
      accountTypeItems: [
        {
          text: "Nurse",
          value: "Nurse",
        },
        {
          text: "NP",
          value: "Nurse Practitioner",
        },
        {
          text: "PA",
          value: "Physician Assistant",
        },
        {
          text: "Doctor",
          value: "Doctor",
        },
        {
          text: "Basic",
          value: "Basic",
        },
      ],
      userTypeItems:[
        {
          text: "Doctor",
          value: "doctor",
        },
        {
          text: "Nurse",
          value: "nurse",
        },
      ],
      authorisingDoctorsList: [],
    };
  },
  computed: {
    firstNameModel: {
      get() {
        return this.createUserData.firstName;
      },
      set(val) {
        this.$emit("data-updated", "firstName", val);
      },
    },
    lastNameModel: {
      get() {
        return this.createUserData.lastName;
      },
      set(val) {
        this.$emit("data-updated", "lastName", val);
      },
    },
    emailModel: {
      get() {
        return this.createUserData.email;
      },
      set(val) {
        this.$emit("data-updated", "email", val);
      },
    },
    passwordModel: {
      get() {
        return this.createUserData.password;
      },
      set(val) {
        this.$emit("data-updated", "password", val);
      },
    },
    accountTypeModel: {
      get() {
        return this.createUserData.apType;
      },
      set(val) {
        this.$emit("data-updated", "apType", val);
      },
    },
    
    authorizingDoctorModal: {
      get() {
        return this.createUserData.authorizingDoctor;
      },
      set(val) {
        this.$emit("data-updated", "authorizingDoctor", val);
      },
    },
  },
  mounted() {
    EventBus.$on("upload-result", (key) => {
      this.$vs.loading.close("#" + key + " > .con-vs-loading");
    });

    this.fetchAuthorizingDoctors()
      .then((res) => {
        this.authorisingDoctorsList = res.data.data;
      })
      .catch((err) => console.log("Error fetching authorize doctors :::", err));
  },
  beforeDestroy() {
    EventBus.$off("upload-result");
  },
  methods: {
    ...mapActions("doctor", ["fetchAuthorizingDoctors"]),
    uploadImage(event, key) {
      this.$vs.loading({
        color: "#3dc9b3",
        background: "white",
        container: "#" + key,
        scale: 0.6,
      });
      this.$emit("upload-image", event, key);
    },
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
  },
};
</script>
